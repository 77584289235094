@font-face {
  font-family: 'Metric';
  src: url('Metric-Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: 'Metric';
  src: url('Metric-Medium.otf') format('opentype');
  font-style: medium;
}

@font-face {
  font-family: 'Metric';
  src: url('Metric-Regular.otf') format('opentype');
  font-weight: normal;
}
